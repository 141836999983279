
import { defineComponent, reactive, computed, toRaw } from 'vue';
import { default as GridContent } from '@/components/base-layouts/grid-content/index.vue';
import { EllipsisOutlined } from '@ant-design/icons-vue';
import { getTimeDistance } from './util';

import IntroduceRow from './components/introduce-row.vue';
import SalesCard from './components/sales-card.vue';
import TopSearch from './components/top-search.vue';
import ProportionSales from './components/proportion-sales.vue';
import OfflineData from './components/offline-data.vue';

import { useAnalysisData } from '@/api/dashboard/analysis';
import { Moment } from 'moment';

export default defineComponent({
  setup() {
    const state = reactive({
      loading: true,
      salesLoading: true,
      activeKey: 'Stores 0',
      salesType: 'all',
      rangePickerValue: getTimeDistance('year'),
    });

    const { state: analysisState, fetchAllData, fetchSalesData } = useAnalysisData();

    const salesPieData = computed(() => {
      const { salesTypeData, salesTypeDataOnline, salesTypeDataOffline } = analysisState.chartData;
      let salesPieData = [];
      if (state.salesType === 'all') {
        salesPieData = salesTypeData;
      } else {
        salesPieData = state.salesType === 'online' ? salesTypeDataOnline : salesTypeDataOffline;
      }
      return salesPieData;
    });

    const handleRangePickerChange = (rangePickerValue: [Moment, Moment]) => {
      state.rangePickerValue = rangePickerValue;
    };

    const selectDate = (type: any) => {
      state.rangePickerValue = getTimeDistance(type);
      const val = toRaw(state.rangePickerValue);
      fetchSalesData([val[0].format(), val[1].format()]);
    };

    const isActive = (type: any) => {
      const value = getTimeDistance(type);
      if (!state.rangePickerValue[0] || !state.rangePickerValue[1]) {
        return '';
      }
      if (
        state.rangePickerValue[0].isSame(value[0], 'day') &&
        state.rangePickerValue[1].isSame(value[1], 'day')
      ) {
        return 'currentDate';
      }
      return '';
    };

    const handleChangeSalesType = (e: any | Event) => {
      state.salesType = e.target.value;
    };

    const handleTabChange = (key: string) => {
      state.activeKey = key;
    };

    fetchAllData();

    return {
      state,
      analysisState,
      salesPieData,

      isActive,
      selectDate,
      handleRangePickerChange,
      handleTabChange,
      handleChangeSalesType,
    };
  },
  components: {
    GridContent,

    IntroduceRow,
    SalesCard,
    TopSearch,
    ProportionSales,
    OfflineData,

    // icons
    EllipsisOutlined,
  },
});
