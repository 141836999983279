
import { defineComponent, reactive, PropType } from 'vue';
import { InfoCircleOutlined } from '@ant-design/icons-vue';
import { useI18n } from 'vue-i18n';
import { MiniArea } from './charts';
import NumberInfo from './number-info/index.vue';
import Trend from './trend/index.vue';

import { SearchDataItem, VisitDataItem } from '@/api/dashboard/analysis';

import numeral from 'numeral';

export default defineComponent({
  props: {
    loading: {
      type: Boolean,
      default: () => false,
    },
    visitData2: {
      type: Array as PropType<VisitDataItem[]>,
      default: () => [],
    },
    searchData: {
      type: Array as PropType<SearchDataItem[]>,
      default: () => [],
    },
  },
  setup() {
    const { t } = useI18n();

    const columns = [
      {
        title: t('dashboard.analysis.table.rank'),
        dataIndex: 'index',
        key: 'index',
      },
      {
        title: t('dashboard.analysis.table.search-keyword'),
        dataIndex: 'keyword',
        key: 'keyword',
        scopedSlots: { customRender: 'keywordRender' },
      },
      {
        title: t('dashboard.analysis.table.users'),
        dataIndex: 'count',
        key: 'count',
        sorter: (a: any, b: any) => a.count.length - b.count.length,
        class: 'alignRight',
      },
      {
        title: t('dashboard.analysis.table.weekly-range'),
        dataIndex: 'range',
        key: 'range',
        sorter: (a: Record<string, number>, b: Record<string, number>) => a.range - b.range,
        scopedSlots: { customRender: 'rangeRender' },
      },
    ];
    const state = reactive({
      columns,
    });
    return {
      t,
      numeral,

      state,
    };
  },
  components: {
    NumberInfo,
    Trend,
    MiniArea,

    // icons
    InfoCircleOutlined,
  },
});
