import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7e712ce1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { href: "/" }
const _hoisted_2 = { style: {"marginright":"4px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_info_circle_outlined = _resolveComponent("info-circle-outlined")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_number_info = _resolveComponent("number-info")!
  const _component_mini_area = _resolveComponent("mini-area")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_trend = _resolveComponent("trend")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_card = _resolveComponent("a-card")!

  return (_openBlock(), _createBlock(_component_a_card, {
    style: {"height":"100%"},
    loading: _ctx.loading,
    bordered: false,
    title: _ctx.t('dashboard.analysis.online-top-search')
  }, {
    extra: _withCtx(() => [
      _renderSlot(_ctx.$slots, "dropdownGroup", {}, undefined, true)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_a_row, {
        gutter: 68,
        type: "flex"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_col, {
            sm: 12,
            xs: 24,
            style: {"marginbottom":"24px"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_number_info, {
                gap: 8,
                total: _ctx.numeral(12321).format('0,0'),
                status: "up",
                "sub-total": 17.1
              }, {
                subTitle: _withCtx(() => [
                  _createElementVNode("span", null, [
                    _createTextVNode(_toDisplayString(_ctx.t('dashboard.analysis.search-users')) + " ", 1),
                    _createVNode(_component_a_tooltip, {
                      title: _ctx.t('dashboard.analysis.introduce')
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_info_circle_outlined, { style: {"marginleft":"8px"} })
                      ]),
                      _: 1
                    }, 8, ["title"])
                  ])
                ]),
                _: 1
              }, 8, ["total", "sub-total"]),
              _createVNode(_component_mini_area, {
                line: "",
                height: 45,
                data: _ctx.visitData2
              }, null, 8, ["data"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_col, {
            sm: 12,
            xs: 24,
            style: {"marginbottom":"24px"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_number_info, {
                total: 2.7,
                status: "down",
                "sub-total": 26.2,
                gap: 8
              }, {
                subTitle: _withCtx(() => [
                  _createElementVNode("span", null, [
                    _createTextVNode(_toDisplayString(_ctx.t('dashboard.analysis.per-capita-search')) + " ", 1),
                    _createVNode(_component_a_tooltip, {
                      title: _ctx.t('dashboard.analysis.introduce')
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_info_circle_outlined, { style: {"marginleft":"8px"} })
                      ]),
                      _: 1
                    }, 8, ["title"])
                  ])
                ]),
                _: 1
              }, 8, ["total", "sub-total"]),
              _createVNode(_component_mini_area, {
                line: "",
                height: 45,
                data: _ctx.visitData2
              }, null, 8, ["data"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_a_table, {
        size: 'small',
        "row-key": record => record.index,
        columns: _ctx.state.columns,
        "data-source": _ctx.searchData,
        pagination: {
        style: { marginBottom: 0 },
        pageSize: 5,
      }
      }, {
        keywordRender: _withCtx(({ text }) => [
          _createElementVNode("a", _hoisted_1, _toDisplayString(text), 1)
        ]),
        rangeRender: _withCtx(({ text, record }) => [
          _createVNode(_component_trend, {
            flag: record.status === 1 ? 'down' : 'up'
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_2, _toDisplayString(text) + "%", 1)
            ]),
            _: 2
          }, 1032, ["flag"])
        ]),
        _: 1
      }, 8, ["row-key", "columns", "data-source"])
    ]),
    _: 3
  }, 8, ["loading", "title"]))
}